// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  overflow: hidden;
  margin: 0;
  height: 100%;
}
canvas {
  display: block;
  width: 100vw;
  height: 100vh;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;EAEE,gBAAgB;EAChB,SAAS;EACT,YAAY;AACd;AACA;EACE,cAAc;EACd,YAAY;EACZ,aAAa;AACf","sourcesContent":["html,\nbody {\n  overflow: hidden;\n  margin: 0;\n  height: 100%;\n}\ncanvas {\n  display: block;\n  width: 100vw;\n  height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
